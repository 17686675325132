var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[(_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l("Edit")))]):_vm._e(),(!_vm.id)?_c('span',[_vm._v(_vm._s(_vm.l("Create")))]):_vm._e()])]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"表单名称","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'formName',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'formName',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入表单名称"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"表单周期(天)","has-feedback":""}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'cycleType',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'cycleType',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入表单周期，填写0天则为一次性表单","min":0,"max":365}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"填写频次","has-feedback":""}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'frequency',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'frequency',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入填写频次","min":0,"max":365}})],1)],1)],1),_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"表单采集时间","has-feedback":""}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'time',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'time',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"show-time":{ format: 'HH:mm' },"format":"YYYY-MM-DD HH:mm","placeholder":['开始时间', '结束时间']}})],1)],1)],1),_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"任务顶部描述","has-feedback":""}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'headerRemark',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'headerRemark',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入任务顶部描述","auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1),_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"任务底部描述","has-feedback":""}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'footerRemark',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'footerRemark',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入任务底部描述","auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1),_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"模板状态","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'formStatus',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'formStatus',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}]},_vm._l((_vm.formStatusList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(_vm._s(item.text))])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"模板类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'formType',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'formType',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],on:{"change":_vm.changeFormType}},_vm._l((_vm.formTypeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(_vm._s(item.text))])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":12}},[(!_vm.isDisabled)?_c('a-form-item',{attrs:{"label":"是否公开"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['isPublic', { valuePropName: 'checked', initialValue: false }]),expression:"['isPublic', { valuePropName: 'checked', initialValue: false }]"}]})],1):_vm._e()],1),_c('a-col',{attrs:{"span":12}})],1),_c('a-form-item',{staticClass:"btn--container"},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l("Save"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }