<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="id">{{ l("Edit") }}</span>
        <span v-if="!id">{{ l("Create") }}</span>
      </div>
    </div>

    <a-form :form="form" @submit="handleSubmit">
      <a-row>
        <a-col :span="24">
          <a-form-item label="表单名称" has-feedback>
            <a-input
              placeholder="请输入表单名称"
              v-decorator="[
                'formName',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="8">
        <a-col :span="12">
          <a-form-item label="表单周期(天)" has-feedback>
            <a-input-number
              style="width: 100%"
              placeholder="请输入表单周期，填写0天则为一次性表单"
              :min="0"
              :max="365"
              v-decorator="[
                'cycleType',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="填写频次" has-feedback>
            <a-input-number
              style="width: 100%"
              placeholder="请输入填写频次"
              :min="0"
              :max="365"
              v-decorator="[
                'frequency',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="8">
        <a-col :span="24">
          <a-form-item label="表单采集时间" has-feedback>
            <a-range-picker
              style="width: 100%"
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD HH:mm"
              :placeholder="['开始时间', '结束时间']"
              v-decorator="[
                'time',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item> </a-col
      ></a-row>
      <a-row :gutter="8">
        <a-col :span="24">
          <a-form-item label="任务顶部描述" has-feedback>
            <a-textarea
              placeholder="请输入任务顶部描述"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                'headerRemark',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="8">
        <a-col :span="24">
          <a-form-item label="任务底部描述" has-feedback>
            <a-textarea
              placeholder="请输入任务底部描述"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                'footerRemark',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item> </a-col
      ></a-row>
      <a-row :gutter="8">
        <a-col :span="12">
          <a-form-item label="模板状态" has-feedback>
            <a-select   v-decorator="[
                'formStatus',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]">
              <!-- <a-select-option key="1" value="有效">有效</a-select-option>
              <a-select-option key="0" value="禁用">禁用</a-select-option> -->
               <a-select-option v-for="(item,index) in formStatusList" :key="index" :value="item.code" >{{ item.text }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
                <a-col :span="12">
          <a-form-item label="模板类型" has-feedback>
            <a-select   v-decorator="[
                'formType',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]" @change='changeFormType'>
              <!-- <a-select-option key="1" value="有效">有效</a-select-option>
              <a-select-option key="0" value="禁用">禁用</a-select-option> -->
               <a-select-option v-for="(item,index) in formTypeList" :key="index" :value="item.code" >{{ item.text }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
       <a-row :gutter="8">
              <a-col :span="12">
          <a-form-item label="是否公开" v-if="!isDisabled">
            <a-switch v-decorator="['isPublic', { valuePropName: 'checked', initialValue: false }]"  />
          </a-form-item>
        </a-col>
        <a-col :span="12">
        </a-col>
      </a-row>
      <a-form-item class="btn--container">
        <a-button type="button" @click="close()">
          {{ l("Cancel") }}
        </a-button>
        <a-button type="primary" html-type="submit">
          {{ l("Save") }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CollectionFormServiceProxy } from "./services/collectionform-proxies";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";

export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-colletionform",
  data() {
    return {
      // 获取到的数据
      entity: {},
      spinning: false,
      _serviceProxy: "",
      commonService: null,
      labelCol: {
        span: 5,
      },
      formStatusList:[{code:1,text:"有效"},{code:0,text:"禁用"}],
      formTypeList:[{code:0,text:"自然人"},{code:1,text:"非自然人类型"},{code:2,text:"执行记录"},{code:3,text:"系统表单"}],
      isDisabled:false
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this._serviceProxy = new CollectionFormServiceProxy(this.$apiUrl, this.$api);
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  async mounted() {
    // console.log(this.id);
    if (this.id) {
      await this.getData();
    }
  },
  methods: {
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      // var res = await this._serviceProxy.getForEdit(this.id);
      try {
        let res = await this.commonService.get({params: {id: this.id}, url: '/api/services/app/Form/GetEditById'})
        // res.formStatus = res.formStatus==0?"禁用":"有效"
        let arr = [];
        arr.push(res.startTime);
        arr.push(res.endTime);
        res.time = arr;
              // console.log("res"+ JSON.stringify(res));
              if(res.formType !== 0){
                this.isDisabled = true;
              }
        this.form.setFieldsValue({
          ...res,
          id: this.id,
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        console.log(err);
        console.log(values);
        console.log(values["time"])
        
        if (!err) {
          try {
            // values.formStatus = values.formStatus == "有效"?1:0;
            values.startTime = values["time"][0]
                            ? moment(values["time"][0]).format(
                                  "YYYY-MM-DD HH:mm:ss"
                              )
                            : "-";
                        values.endTime = values["time"][1]
                            ? moment(values["time"][1]).format(
                                  "YYYY-MM-DD HH:mm:ss"
                              )
                            : "-"; 
                            console.log(values);
            this.spinning = true;
            let res = await this._serviceProxy.createOrUpdate({
              ...values,
              id: this.id,
            });
            this.spinning = false;
            console.log(res);
            if (res.status == 200) {
              this.$notification["success"]({
                message: this.l("SavedSuccessfully"),
              });
              this.success(true);
            }
          } catch {
            this.spinning = false;
          }
        }
      });
    },
    changeFormType(item){
      // 自然人之外
      if(item !== 0){
        this.form.setFieldsValue({
          isPublic:false
        });
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
  display: block;
  margin: 10px auto;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
