<template>
  <a-spin :spinning="spinning">
    <!-- <page-header :title="l('CollectionFormManage')"></page-header> -->
    <!-- table -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :scroll="{ x: 1000 }"
      :is-full="true"
      :columns="columns"
      :hide-row-selection="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <!-- 按钮 -->
      <a-row :gutter="8" class="btn--container">
        <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="filterText"
            :placeholder="l('表单名称')"
            allowClear
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            :placeholder="l('表单类型')"
            v-model="formType"
            style="width: 98%"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in formTypeList"
              :key="index"
              :value="item.code"
              >{{ item.text }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            :placeholder="l('表单状态')"
            v-model="formStatus"
            style="width: 98%"
            allowClear
          >
            <a-select-option
              v-for="(item, index) in formStatuList"
              :key="index"
              :value="item.code"
              >{{ item.text }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="search"
            v-if="isGranted('Pages.LGModul.CustomForm.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>

        <a-col class="gutter-row" :span="8" style="text-align: right">
          <a-button
            type="primary"
            @click="createOrEdit()"
            v-if="isGranted('Pages.LGModul.CustomForm.Create')"
          >
            <a-icon type="plus" />
            {{ l("Create") }}
          </a-button>
          <!-- <a-button
              @click="exportToExcel()"
            >
              <a-icon type="file-excel" />
              <span>{{ l("Export") }}</span>
            </a-button> -->
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CollectionFormServiceProxy } from "./services/collectionform-proxies";
import { CommonServiceProxy, initFormType } from "@/shared/common-service";
import EditForm from "./edit.vue";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";
// import { ListTable } from "./components/index";
import EleTable from "@/components/ele-table";
import FieldManageForm from "./FieldManage.vue";

export default {
  mixins: [AppComponentBase],
  name: "bannerads",
  components: { EleTable },
  data() {
    return {
      spinning: false,
      // 搜索
      filterText: "",
      formType: undefined,
      formStatus:undefined,
      // 选择多少项
      selectedRowKeys: [],
      tableData: [],
      tableKey: "CollectionFormPagedDto",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: {
        sorting: "",
        maxResultCount: 50,
        skipCount: 0,
      },
      // 用户表格
      columns: [
        {
          title: this.l("表单名称"),
          dataIndex: "formName",
          sorter: false,
          align: "center",
          ellipsis: true,
          width: 200,
          scopedSlots: { customRender: "formName" },
        },
        {
          title: this.l("表单状态"),
          dataIndex: "formStatus",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "formStatus" },
        },
        {
          title: this.l("表单类型"),
          dataIndex: "formType",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "formType" },
          customRender: (text, record, index) => {
            switch (text) {
              case 0:
                return "自然人";
              case 1:
                return "非自然人类型";
              case 2:
                return "执行记录";
              case 3:
                return "系统表单";
            }
            return "";
          },
        },
        {
          title: this.l("收集开始时间"),
          dataIndex: "startTime",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "startTime" },
        },
        {
          title: this.l("收集结束时间"),
          dataIndex: "endTime",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "endTime" },
        },
        {
          title: this.l("周期"),
          dataIndex: "cycleType",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "cycleType" },
        },
        {
          title: this.l("频次"),
          dataIndex: "frequency",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "frequency" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          fixed: "right",
          width: 280,
          scopedSlots: { customRender: "actions" },
        },
      ],
      _serviceProxy: "",
      commonService: null,
      actionsType: [],
      formTypeList: [
        // { code: -1, text: "请选择表单类型" },
        { code: 0, text: "自然人" },
        { code: 1, text: "非自然人类型" },
        { code: 2, text: "执行记录" },
        { code: 3, text: "系统表单" },
      ],
      formStatuList:[
        {code:1,text:"有效"},
        {
          code:0,text:"禁用"
        }
      ],
    };
  },
  created() {
    this._serviceProxy = new CollectionFormServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.getData();
    this.initActionsType();
  },
  methods: {
    search() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      var response = await this._serviceProxy.getPaged(
        this.filterText,
        this.formType > -1 ? this.formType : undefined,
        this.formStatus,
        this.request.sorting,
        this.request.maxResultCount,
        this.request.skipCount
      );
      this.spinning = false;
      console.log(response.items);
      this.tableData = response.items;
      this.tableData.map((item) => {
        item.startTime = item.startTime
          ? moment(item.startTime).format("YYYY-MM-DD")
          : "-";
        item.endTime = item.endTime
          ? moment(item.endTime).format("YYYY-MM-DD")
          : "-";
        item.formStatus = item.formStatus == 1 ? "有效" : "禁用";
        item.formType = initFormType(item.formType)
      });
      this.totalItems = response.totalCount;
      this.totalPages = Math.ceil(
        response.totalCount / this.request.maxResultCount
      );
      this.pagerange = [
        (this.pageNumber - 1) * this.request.maxResultCount + 1,
        this.pageNumber * this.request.maxResultCount,
      ];
    },
    /**
     * table选择事件
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * table选择事件
     */

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      // console.log(this.selectedRows);
    },
    /**
     * 分页
     */
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.formType = undefined;
      this.formStatus = undefined;
      this.filterText = "";
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 新建修改
     */
    createOrEdit(id) {
      // console.log(id);
      ModalHelper.create(
        EditForm,
        {
          id: id,
        },
        {
          width: "650px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },

    /**
     * 清空
     */
    restCheckStatus() {
      this.selectedRowKeys = [];
    },
    /**
     * 单个删除
     */
    deleteItem(id) {
      this.spinning = true;
      this._serviceProxy
        .deleteCollectionForm(id)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    /**
     * 导出为excel
     */
    exportToExcel() {
      this.spinning = true;
      this._serviceProxy.getToExcelFile().then((result) => {
        this.spinning = false;
        fileDownloadService.downloadTempFile(result);
      });
    },
    /**
     * emit新建修改
     */
    // edit(newV) {
    //   console.log(newV);
    //   let { id } = newV;
    //   this.createOrEdit(id);
    // },
    //模板字段管理
    // fieldManage(newV) {
    //   console.log(newV);
    //   let { id } = newV;
    //   this.fieldManageForm(id);
    // },
    fieldManageForm(id) {
      ModalHelper.create(
        FieldManageForm,
        {
          id: id,
        },
        {
          width: "860px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    /**
     * 排序更新data
     */
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    /**
     * 分页
     */
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    /**
     * table选择事件
     */
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },

    /**
     * 初始化需要显示的func btn
     */
    initActionsType() {
      let _this = this;
      let obj = {
        type: "CollectionForm",
        isShow: true,
        fns: {
          detailList: [
            {
              granted: this.isGranted("Pages.LGModule.CustomForm.FormDesign"),
              name: "表单设计",
              icon: "profile",
              fn: (data) => {
                this.$router.push({
                  path: `/app/fs/collectionformEdit/${data.id}`,
                });
              },
            },
             {
              granted: this.isGranted("Pages.LGModule.CustomForm.FormDesignV2"),
              name: "表单设计(new)",
              icon: "profile",
              fn: (data) => {
                this.$router.push({
                  path: `/app/fs/newCollectionformEdit/${data.id}`,
                  query: {fromPath: this.$route.fullPath},
                });
              },
            },
          ],
          isShow: true,
          dropList: [
            {
              granted: this.isGranted("Pages.LGModule.CustomForm.FieldManage"),
              name: this.l("模板字段管理"),
              icon: "profile",
              fn: (data) => {
                _this.fieldManageForm(data.id);
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.CustomForm.Edit"),
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data.id);
              },
            },
            {
              delete: true,
              granted: this.isGranted("Pages.LGModul.CustomForm.Delete"),
              name: this.l("Delete"),
              fn: (data) => {
                _this.deleteItem(data.id);
              },
            },
          ],
          // delete: {
          //   granted: this.isGranted("Pages.LGModul.CustomForm.Delete"),
          //   name: this.l("Delete"),
          //   fn: (data) => {
          //     _this.deleteItem(data.id);
          //   },
          // },
        },
      };
      this.actionsType = obj;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  // margin-bottom: 20px;
}

.pagination {
  margin: 10px auto;
  text-align: right;
}

.table-plus,
.table-delete {
  margin-left: 10px;
}
</style>
